import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';

/**
 * Componente para la página de error 404.
 */
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <CenteredBlock>
      <ErrorBlock>
        <h1>404</h1>
        <Separator />
        <h2>Página no encontrada</h2>
      </ErrorBlock>
      <p>La página solicitada no existe. Por favor, usa los enlaces
        de la barra de menú para navegar por el sitio web.
      </p>
    </CenteredBlock>
  </Layout>
);

// Componente de estilo para centrar el mensaje del error
const CenteredBlock = styled.div`
  width: 40%;
  min-width: 300px;
  height: 300px;
  margin: auto;

  h1 {
    font-family: var(--ff-banner), Arial, sans-serif;
    font-size: 64px;
    margin: 0;
  }

  h2 {
    font-family: var(--ff-banner), Arial, sans-serif;
    font-size: 28px;
    font-weight: 300;
    margin: auto 0;
  }

  p {
    font-family: var(--ff-banner), Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    margin: 1rem 0 0 0;
  }
`;

// Componente para el código del error y el mensaje
const ErrorBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

// Componente de separador
const Separator = styled.span`
    display: inline;
    border-left: 1px solid var(--clr-grey-5);
    margin: auto 30px;
    height: 3rem;
`;

export default NotFoundPage;
